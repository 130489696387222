import styled from 'styled-components/macro';
import { Button } from './StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalEndSubscription = (props) => {
  const { close, cancelSubscription } = props;

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content>
      <p style={{ marginBottom: 15 }}>Annuler l'abonnement ?</p>
      <p>Vous continuerez à bénéficier de vos privilèges jusqu'à la date de fin prévue.</p>
      <S.Buttons>
        <Button onClick={close} outline={true}>Retour</Button>
        <Button onClick={cancelSubscription}>Confirmer</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={close}></S.Background>
  </S.Container>
};

export default ModalEndSubscription;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 400px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 1000;
  text-align: center;
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  z-index: 999;
  position: fixed;
  background: #000000a3;
`