import { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import { getValue, prepareFormData } from '../contexts/Utils';
import Formfields, { UploadFileStyle } from '../components/form/Formfields';
import { useNotification } from '../components/UseNotification';
import { Button } from '../components/StyledComponents';
import VehiclesManagement from '../components/form/VehiclesManagement';
import Team from '../components/form/Team';
import { Context } from '../App';
import userPicture from '../assets/user.png';
import ModalEndSubscription from '../components/ModalEndSubscription';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Profile = () => {
  const { theme, settings } = useContext(Context);
  const { notification } = useNotification();
  const cookies = useMemo(() => new Cookies(), []);
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [stripeEnabled, setStripeEnabled] = useState(user.stripe_charges_enabled === 1);
  const [stripeSubscriptionEnabled, setStripeSubscriptionEnabled] = useState(user.stripe_subscription_enabled === 1);
  const [stripeSubscriptionWillEnd, setStripeSubscriptionWillEnd] = useState(user.stripe_subscription_will_end === 1);
  const [subscriptionData, setSubscriptionData] = useState();
  const profilePicture = useRef(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [modalCancelSubscriptionOpen, setModalCancelSubscriptionOpen] = useState(false);
  const isDriver = user.active_role === 3
  const isStBernardDriver = user.active_role === 3 && user.driver_type === 1;
  const isDisabled = user.disabled === 1 || user.status === 1;
  const firstRender = useRef(true);

  //
  // ─── UPDATE COOKIE ───────────────────────────────────────
  //
  const updateCookie = useCallback((fields) => {
    const newUserData = {
      ...user,
      ...fields
    };

    cookies.set(process.env.REACT_APP_USER_COOKIE, newUserData, {
      expires: new Date(Date.now() + 604800000), // time until expiration
      secure: false, // true if using https
      path: '/' // if not set, will gen cookie for different pages
    })
  }, [cookies, user])

  // 
  // ─── GET USER DATA ───────────────────────────────────────
  //
  useEffect(() => {
    const getData = async () => {
      // 
      // ─── GET PERSONAL DATA ───────────────────────────────────────
      //
      const fields = [
        'user_id',
        'username',
        'firstname',
        'name',
        'phone',
        'email',
        'hourly_price',
        'postal_code',
        'city',
        'address',
        'iban',
        'description'
      ]

      const query = { fields, user_get: user.user_id, disabled: 3 }
      if (user.active_role === 2) query.getVehicles = true;
      if (user.active_role === 3) query.getProfilePicture = true;

      const params = {
        query,
        endpoint: '/users',
        method: 'GET'
      }

      let promiseArray = [Api(params)];
      let getTeams = false, getSubscription = false;

      // 
      // ─── GET TEAM ───────────────────────────────────────
      //
      if (isStBernardDriver && !isDisabled) {
        const teamParams = {
          query: { user_get: user.user_id },
          endpoint: '/teams',
          method: 'GET'
        }

        promiseArray.push(Api(teamParams));
        getTeams = true;
      }

      if (isDriver && (!stripeSubscriptionEnabled && !stripeSubscriptionWillEnd)) {
        const stripeParams = {
          query: { postal_code: user.postal_code },
          endpoint: '/stripe/subscription',
          method: 'GET'
        }

        promiseArray.push(Api(stripeParams));
        getSubscription = true;
      }

      await Promise.all(promiseArray).then((arr) => {
        const user = arr[0];
        const teams = getTeams ? arr[1] : null;
        const subscription = getSubscription ? getTeams ? arr[2] : arr[1] : null;

        let obj = user.data;
        if (teams && teams.success) obj.team = teams.data;
        if (subscription && subscription.success) setSubscriptionData(subscription.data);

        // update profile picture render
        if (obj.profile_picture) profilePicture.current.src = `data:image/jpeg;base64, ${obj.profile_picture}`;

        // update state
        setData(obj);
      });
    }

    getData();
  }, [user.user_id, user.active_role, isStBernardDriver, isDisabled, isDriver, user.postal_code, user.stripe_subscription_enabled, stripeSubscriptionEnabled, stripeSubscriptionWillEnd])

  //
  // ─── CHECK STRIPE ACCOUNT VALIDITY ───────────────────────────────────────
  //
  useEffect(() => {
    const checkStripeAccount = async () => {
      const search = new URLSearchParams(window.location.search);
      const validate = search.get('validate');

      if (Boolean(validate)) {
        const params = {
          endpoint: '/stripe/account/validate',
          method: 'POST',
        }
        const query = await Api(params);

        if (query.success) {
          // update cookie with new infos
          const newUserData = {
            ...user,
            stripe_charges_enabled: 1
          };

          cookies.set(process.env.REACT_APP_USER_COOKIE, newUserData, {
            expires: new Date(Date.now() + 604800000), // time until expiration
            secure: false, // true if using https
            path: '/' // if not set, will gen cookie for different pages
          })

          // update state
          setStripeEnabled(true);
        };
      }
    }

    user.stripe_charges_enabled !== 1 && checkStripeAccount();
  }, [cookies, user])

  //
  // ─── CHECK IF SUBSCRIPTION IS COMPLETED ───────────────────────────────────────
  //
  useEffect(() => {
    const checkSubscriptionValidity = async () => {
      firstRender.current = false;

      const search = new URLSearchParams(window.location.search);

      // if no success, paiement failed
      const querySuccess = search.get('success');
      if (querySuccess === null || !Number(querySuccess)) return;

      // check session validity -> add to db if success
      const session_id = search.get('session_id');

      if (session_id) {
        const params = {
          endpoint: `/stripe/subscription/validate`,
          method: 'POST',
          data: { session_id }
        }
        const query = await Api(params);
        if (query.success) {
          // update cookie
          updateCookie({ stripe_subscription_enabled: 1 });

          // update state
          setStripeSubscriptionEnabled(true);
        }
      }
    }

    if (firstRender.current) checkSubscriptionValidity();
  }, [updateCookie])

  // 
  // ─── HANDLE LOGOUT ───────────────────────────────────────
  //
  const handleLogout = async () => {
    const params = { endpoint: '/auth/logout' }
    const logoutUser = await Api(params)

    if (logoutUser && logoutUser.success) {
      cookies.remove(process.env.REACT_APP_USER_COOKIE)
      return window.location = "/connexion";
    }
  }

  // 
  // ─── SHOW PASSWORD FIELDS ───────────────────────────────────────
  //
  const addPassword = () => setShowPassword(true);

  // 
  // ─── HANDLE SUBMIT INFORMATIONS ───────────────────────────────────────
  //
  const handleSubmitInformations = async (e) => {
    e.preventDefault();

    const formData = prepareFormData({ formId: 'informations-form' })
    if (formData.errors) return setErrors(formData.errors)
    else setErrors({});

    if (user.active_role === 3 && Number(formData.fields.hourly_price) > Number(settings.max_hourly_price) && user.stripe_subscription_enabled == 0) {
      return setErrors({ hourly_price: { message: `Le prix horaire ne peut pas être supérieur à ${settings.max_hourly_price}€` } });
    }

    const fields = formData.fields;

    // 
    // ─── SUBMIT PASSWORD ───────────────────────────────────────
    //
    const update_password = showPassword && Boolean(fields.password_old.length && fields.password.length);
    if (update_password) {
      const passwordfields = { password: fields.password, password_old: fields.password_old }
      const passwordParams = {
        data: { fields: passwordfields, update_password: true, user_edit: user.user_id },
        endpoint: '/users',
        method: 'PUT'
      }
      const passwordUpdate = await Api(passwordParams);
      notification({ variant: passwordUpdate.success ? 'success' : 'error', message: passwordUpdate.message })
    }

    // 
    // ─── SUBMIT OTHER INFOS ───────────────────────────────────────
    //
    delete fields.password_old;
    delete fields.password;

    if (fields.email === data.email) delete fields.email;

    const infoParams = {
      data: { fields, user_edit: user.user_id },
      endpoint: '/users',
      method: 'PUT'
    }
    const userUpdate = await Api(infoParams);

    notification({ variant: userUpdate.success ? 'success' : 'error', message: userUpdate.message })

    // update cookie with new infos
    if (userUpdate.success) {
      const newUserData = {
        ...user,
        firstname: fields.firstname,
        name: fields.name,
        hourly_price: fields.hourly_price,
      };

      setData(prev => ({ ...prev, ...fields }));

      cookies.set(process.env.REACT_APP_USER_COOKIE, newUserData, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
        path: '/' // if not set, will gen cookie for different pages
      })
    }
  }

  //
  // ─── GET SUBSCRIPTION SESSION URL ───────────────────────────────────────
  //
  const getSubscriptionUrl = async () => {
    const params = {
      endpoint: '/stripe/subscription',
      method: 'POST'
    }
    const query = await Api(params);
    if (query.success) {
      // open session url
      if (query.data) window.open(query.data, '_blank');

      else {
        // update cookie
        updateCookie({ stripe_subscription_enabled: 1, stripe_subscription_will_end: 0 });

        // update states
        setStripeSubscriptionEnabled(true);
        setStripeSubscriptionWillEnd(false);
        notification({ variant: 'success', message: query.message });
      }
    }
    else notification({ variant: 'error', message: query.message || "Impossible d'accéder à Stripe" });
  }

  //
  // ─── CANCEL SUBSCRIPTION ───────────────────────────────────────
  //
  const cancelSubscription = async () => {
    const query = await Api({
      endpoint: '/stripe/subscription/cancel',
      method: 'POST'
    });

    if (query.success) {
      // update cookie
      updateCookie({ stripe_subscription_will_end: 1 });
      setStripeSubscriptionWillEnd(true);

      notification({ variant: 'success', message: 'Abonnement annulé' })
      setModalCancelSubscriptionOpen(false);
    }
    else notification({ variant: 'error', message: "Impossible d'annuler l'abonnement" });
  }

  // 
  // ─── GET STRIPE LINK TO CREATE OR UPDATE ACCOUNT ───────────────────────────────────────
  //
  const getStripeLink = async () => {
    const params = {
      endpoint: '/stripe/account',
      method: 'POST'
    }
    const query = await Api(params);
    if (query.success) window.open(query.data, '_blank');
    else notification({ variant: 'error', message: "Impossible d'accéder au compte" });
  }

  // 
  // ─── UPDATE PROFILE PICTURE ───────────────────────────────────────
  //
  const UpdateProfilePicture = () => {
    const handleFileChange = async (e) => {
      const file = e.target.files[0];

      if (file) {
        // get file extension
        const name = file.name.split('.');
        const extension = name[name.length - 1];
        // create new file to update file name
        const formattedFile = new File([file], `profile_picture.${extension}`, { type: file.type });

        // update in server
        const formData = new FormData();
        formData.append('files[]', formattedFile);
        formData.append('fields', JSON.stringify({}));
        formData.append('user_id', user.user_id);

        const params = {
          data: formData,
          removeHeader: true,
          endpoint: '/users',
          method: 'PUT'
        }
        await Api(params);

        // update in render
        profilePicture.current.src = URL.createObjectURL(file);
        notification({ variant: 'success', message: 'Photo de profil mise à jour' })
      }
    };

    return <S.UploadFileStyle>
      <label htmlFor="file">
        <span className="download">Changer la photo de profil</span>
      </label>
      <input type="file" id="file" onChange={handleFileChange} />
    </S.UploadFileStyle>
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  let formFields = [
    { name: 'username', label: "Nom d'utilisateur", required: true, disabled: true },
    { width: 'calc(50% - 5.5px)', name: "firstname", label: 'Prénom', required: true },
    { width: 'calc(50% - 5.5px)', name: "name", label: 'Nom', required: true },
    { type: "email", name: "email", label: "Email", required: true },
    { type: "tel", name: "phone", label: "Numéro de téléphone", required: true },
    { cond: isDriver, component: 'textarea', name: "description", label: "Description", required: false },
    { cond: showPassword, type: "password", name: "password_old", label: 'Mot de passe actuel', required: true },
    { cond: showPassword, type: "password", name: "password", label: 'Nouveau mot de passe', tip: 'Le mot de passe doit contenir au minimum 8 caractères, un chiffre, une majuscule et une minuscule', required: true, dataName: 'password' },
  ]

  let hourlyPriceField;
  if (isDriver) hourlyPriceField = { width: 'calc(50% - 5.5px)', type: "number", step: '0.01', name: "hourly_price", label: "Prix/minute (€)", min: settings.min_hourly_price, max: user.stripe_subscription_enabled == 1 ? undefined : settings.max_hourly_price, required: true, default: data.hourly_price, noStar: true, error: getValue(errors, ['hourly_price', 'message']) }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div>
    {modalCancelSubscriptionOpen && <ModalEndSubscription cancelSubscription={cancelSubscription} close={() => setModalCancelSubscriptionOpen(false)} />}
    <div className='page-container'>
      <S.Content theme={theme}>
        <S.Header>
          <img src={userPicture} ref={profilePicture} alt="Profil" />
          <div>
            <h1>{user.firstname} {user.name.charAt(0)}.</h1>
            <span onClick={handleLogout}>
              Déconnexion
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.3 244.7l-144-144c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L457.4 240H176C167.2 240 160 247.2 160 256s7.156 16 16 16h281.4l-116.7 116.7c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0l144-144C510.4 264.2 512 260.1 512 256S510.4 247.8 507.3 244.7zM176 448h-96C53.53 448 32 426.5 32 400v-288C32 85.53 53.53 64 80 64h96C184.8 64 192 56.84 192 48S184.8 32 176 32h-96C35.88 32 0 67.88 0 112v288C0 444.1 35.88 480 80 480h96C184.8 480 192 472.8 192 464S184.8 448 176 448z" /></svg>
            </span>
          </div>
          {user.active_role === 3 && <UpdateProfilePicture />}
        </S.Header>
        {user.active_role === 3 && <S.Subscription>
          <h2>Stripe</h2>
          <S.Button onClick={getStripeLink}>{stripeEnabled ? 'Gérer' : 'Créer'} mon compte Stripe</S.Button>
          <h2 style={{ marginTop: 30 }}>Mon abonnement</h2>
          {(!stripeSubscriptionEnabled && !stripeSubscriptionWillEnd) && subscriptionData && <S.SubscriptionInfos>
            <p><span className="stripe-price">{subscriptionData.price.toFixed(2)}€</span> / mois</p>
            <p><span className='stars'>★</span> Vous verrez les missions avant les autres chauffeurs</p>
            <p><span className='stars'>★</span> Pas de marge pour Saint Bernard</p>
            <S.Button onClick={getSubscriptionUrl}>Débuter mon abonnement</S.Button>
          </S.SubscriptionInfos>}
          <div>
            {(stripeSubscriptionWillEnd) && <S.Button onClick={getSubscriptionUrl}>Réactiver mon abonnement</S.Button>}
            {(stripeSubscriptionEnabled && !stripeSubscriptionWillEnd) && <S.Button className="button-border" outline={true} onClick={() => setModalCancelSubscriptionOpen(true)}>Annuler mon abonnement</S.Button>}
          </div>
        </S.Subscription>}
        <S.Informations id="informations-form" onSubmit={handleSubmitInformations}>
          <h2>Informations</h2>
          {formFields.map((e) => ((e.cond || !e.hasOwnProperty('cond')) && <Formfields key={e.name || e.label} field={{ ...e, default: getValue(data, [e.name]), noStar: true, error: getValue(errors, [e.name, 'message']) }} />))}
          {!showPassword && <span onClick={addPassword}>Modifier le mot de passe</span>}
          {isDriver && <>
            <h2 className='price-title'>Prix par minute</h2>
            <Formfields field={hourlyPriceField} />
          </>}
          <div className='button'>
            <div></div>
            <S.Button as="input" type="submit" value="Enregistrer les modifications" />
          </div>
        </S.Informations>
        {user.active_role === 2 && <S.Vehicles>
          <h2>Mes véhicules</h2>
          <VehiclesManagement data={data} setData={setData} errors={errors} setErrors={setErrors} />
        </S.Vehicles>}
        {isStBernardDriver && !isDisabled && <S.Team>
          <h2>Mon équipe</h2>
          <Team data={data} setData={setData} />
        </S.Team>}
      </S.Content>
    </div>
  </div>
}

export default Profile;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Content = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 700px;
  box-sizing: border-box;

  h1, h2 {
    font-size: 22px;
    width: 100%;
  }

  @media(min-width: 800px) {
    padding-top: 0;

    ${({ theme }) => theme === 'dark' && css`
      input:not([type="submit"]), textarea {
        border: 1.5px solid #8a8a8a !important;
        border-radius: 5px;
      }
    `}
  }

  textarea {
    height: 130px;
  }
`

S.Header = styled.div`
  display: flex;
  flex-direction: column;
  
  // profile
  > img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    fill: ${({ theme }) => theme.inputBg};
    margin-bottom: 5px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      display: flex;
      gap: 13px;

      svg {
        cursor: pointer;
        width: 22px;
        fill: ${({ theme }) => theme.inputBg};
      }
    }

    // déconnexion
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 14px;
      transition: .2s;

      svg {
        height: 14px;
        fill: ${({ theme }) => theme.text};
        transition: .2s !important;
      }

      &:hover, &:hover svg {
        color: ${({ theme }) => theme.primary};
        fill: ${({ theme }) => theme.primary};
      }
    }
  }
`

S.Subscription = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    width: fit-content;
  }

  h3 {
    font-size: 40px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.primaryFont};
    margin: 10px 0;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    button {
      width: fit-content;
      
      .button-border {
        border: ${({ theme }) => `1px solid ${theme.text}`};
        padding: 9px 20px;
      }
    }
  }
`

S.SubscriptionInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.background};
  border-radius: 10px;
  width: fit-content;
  padding: 20px;
  margin-top: 10px;
  gap: 8px !important;
  max-width: 300px;
  line-height: 1.5;

  p:first-of-type {
    margin-bottom: 10px;
  }

  .stripe-price {
    font-size: 30px;
    font-weight: bold;
  }

  .stars {
    color: ${({ theme }) => theme.primary};
    margin-right: 5px;
  }

  @media (max-width: 800px) {
    background: ${({ theme }) => theme.card};
  }
`

S.Informations = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 11px;

  h2 {
    margin-bottom: 4px;
  }

  .price-title {
    margin-top: 15px;
  }

  & > span {
    color: ${({ theme }) => theme.primary};
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    cursor: pointer;
    width: 100%;
  }

  .button {
    display: flex;
    width: 100%;

    @media(min-width: 520px) {
      gap: 11px;
      & > input, & > div {
        flex: 1;
      }
      & > input {
        padding: 10px 0;
      }
    }
  }
`

S.Button = styled(Button)`
  height: fit-content;
  margin-top: 12px;
`

S.Vehicles = styled.div`
  h2 {
    margin-bottom: 15px;
  }

  .button {
    display: flex;
    width: 100%;

    @media(min-width: 520px) {
      gap: 11px;
      & > button, & > div {
        flex: 1;
      }
      & > button {
        padding: 10px 0;
      }
    }
  }
`

S.Team = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 20px;
  }
`

S.UploadFileStyle = styled(UploadFileStyle)`
  margin-top: 15px;

  .download {
    width: 225px !important;
    font-size: 14px !important;

    :hover {
      color: ${({ theme }) => theme.textInverse};
    }
  }

  label {
    width: fit-content;
    margin-right: auto;
  }
`

S.StripeModal = styled.div`
  position: fixed;
`