import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const PostulationWarning = (props) => {
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);

  let message;
  if (user.status === 1) message = 'Votre compte est en cours de validation par notre équipe. Vous pourrez postuler à des missions dès que votre compte sera validé.'
  else if (((user.driver_type == 1 && user.limit_amount_reached) || user.driver_type == 2) && user.stripe_subscription_enabled == 0) {
    message = 'Vous devez souscrire à un abonnement pour postuler à des missions.'
  }
  else if (user.disabled === 1 && user.active_role === 3) message = 'Votre compte a été banni. Vous ne pouvez plus postuler à des missions.'
  else if (user.disabled === 1 && user.active_role === 2) message = 'Votre compte a été banni. Vous ne pouvez plus créer ou modifier des missions.'
  else message = "Vous devez terminer l'inscription de votre compte Stripe pour postuler à une mission"

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container as={user.disabled === 1 ? 'p' : Link} to="/profil" className='postulations-warning' $color={props.color}>
    <span>{message}</span>
  </S.Container>
};

export default PostulationWarning;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled(Link)`
  display: block;
  background: ${props => props.theme.error};
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;

  ${props => props.$color && `
    color: ${props.$color};
  `}
`