import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { getValue, isInReunion } from '../../contexts/Utils';
import Formfields from './Formfields';
import { Radio, RadioContainer } from '../StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TravelFormTime = (props) => {
  const { errors, data = {} } = props;
  const [dateType, setDateType] = useState(Number(data.date_type) || 1);
  const getIsoDate = (date) => new Date(new Date(date).setHours(new Date(date).getHours() + (data.date ? 2 : 2))).toISOString().slice(0, 16);

  let today = new Date();
  const choosedDateDefault = new Date();
  if (isInReunion(data.origin_cp)) choosedDateDefault.setHours(choosedDateDefault.getHours() + 2);
  const [choosedDate, setChoosedDate] = useState(typeof data.date == 'number' ? getIsoDate(data.date) : data.date || getIsoDate(choosedDateDefault));
  const [urgency, setUrgency] = useState(data.urgency || false);

  const fourDaysAgo = new Date();
  fourDaysAgo.setDate(fourDaysAgo.getDate() + 4);
  const minDate = new Date().toISOString().slice(0, 16);
  const maxDate = fourDaysAgo.toISOString().slice(0, 16);


  const getTimeWithZero = (time) => {
    return `${time.getHours() < 10 ? '0' : ''}${time.getHours()}:${time.getMinutes() < 10 ? '0' : ''}${time.getMinutes()}`
  }

  let currentTime = getTimeWithZero(today);

  const formatDate = new Date(choosedDate);
  let choosedTime = getTimeWithZero(formatDate);
  let choosedDateFormatted = formatDate.toLocaleDateString('fr-FR');

  const handleChange = (e) => {
    const newValue = Number(e.target.value);
    setDateType(newValue);
  }

  const handleTimeChange = (e) => {
    const value = e.target.value;
    setChoosedDate(value);
  }

  // when click on datetime, check the radio
  useEffect(() => {
    const input = document.querySelector('#date');
    const radio = document.querySelector('#date_type_2');
    input.addEventListener('click', () => radio.click())
  }, [])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <RadioContainer>
      <S.Radio htmlFor="date_type_1" checked={dateType === 1}>
        <input type='radio' checked={dateType === 1} value={1} id="date_type_1" name="date_type" onChange={handleChange} />
        <span><strong>Dès que possible</strong><br />Il est <strong>{currentTime}</strong></span>
      </S.Radio>
      <S.Radio htmlFor="date_type_2" checked={dateType === 2}>
        <input type='radio' checked={dateType === 2} value={2} id="date_type_2" name="date_type" onChange={handleChange} />
        <S.InputDate>
          <label htmlFor='date'>
            <strong>{choosedTime}</strong>
            <span>
              <svg className='calendar-icon' width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" /></svg>
              <span>{choosedDateFormatted}</span>
              <input onChange={handleTimeChange} value={choosedDate} type='datetime-local' id="date" name="date" min={minDate} max={maxDate} />
            </span>
          </label>
        </S.InputDate>
      </S.Radio>
    </RadioContainer>
    <S.Urgency>
      <Formfields field={{ default: data.urgency, component: 'checkbox', name: 'urgency', label: 'Urgence', onChange: () => setUrgency(!urgency) }} />
      {Boolean(urgency) && <Formfields field={{ default: data.urgency_type, component: 'select', name: 'urgency_type', label: "Type d'urgence", data: [{ id: 1, label: 'Perte de permis' }], required: true, error: getValue(errors, ['urgency_type', 'message']) }} />}
    </S.Urgency>
  </S.Container>
};

export default TravelFormTime;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
    margin-top: 15px;
  }
`

S.Radio = styled(Radio)`
  .calendar-icon {
    width: 20px !important;
  }
`

S.InputDate = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // label du datetime
  label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;

    // hours
    & > strong {
      font-size: 17px;
    }
    
    // svg + date
    span {
      font-size: 15px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 20px;
        margin-right: 10px;
      }
    }
  }

  input {
    display: block;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}
`

S.Urgency = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`